import gql from "graphql-tag";

export const updateChannelGql = gql`
  mutation UpdateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      ... on EmailChannel {
        id
        created
        value
        isVerified
        isPrimary
        isPublic
        verified
        verificationCodeLastSent
        verificationLastAttempt
        verificationAttemptCount
        updated
      }
      ... on MobileChannel {
        id
        created
        value
        isVerified
        isPrimary
        isPublic
        verified
        verificationCodeLastSent
        verificationLastAttempt
        verificationAttemptCount
        updated
      }
      ... on DomainChannel {
        id
        created
        value
        isVerified
        isPrimary
        isPublic
        verified
        verificationLastAttempt
        verificationAttemptCount
        updated
      }
      ... on SocialChannel {
        id
        value
        isVerified
        isPrimary
        isPublic
        verified
        provider
        created
        updated
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
