import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile-view-select-contacts" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_ProfileContactItem = _resolveComponent("ProfileContactItem")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonCardLayout, { class: "profile-view-select-contacts__card-layout mb-8" }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "text",
            class: "p-0",
            onClick: _ctx.backToProfile,
            size: "small"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.BackIcon,
                alt: "back",
                class: "mr-3",
                height: "24"
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_typography_title, {
            level: 3,
            class: "m-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Select Contacts")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verifiedChannelList, (channel, index) => {
          return (_openBlock(), _createBlock(_component_ProfileContactItem, {
            key: channel.id || index,
            channel: channel,
            class: "card-border-bottom"
          }, null, 8, ["channel"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_a_button, { onClick: _ctx.backToProfile }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Back to profile")), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}