
import { TypeProfileDetails } from "@/web/types/Profile";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import ProfileViewHeader from "@/web/views/Profile/ProfileView/ProfileViewHeader.vue";
import ProfileViewContactDetails from "@/web/views/Profile/ProfileView/ProfileViewContactDetails.vue";
import EmptyProfileViewContact from "@/web/views/Profile/ProfileView/EmptyProfileViewContact.vue";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results } from "@/api/communicationChannels/__generated__/CommunicationChannels";

export default defineComponent({
  props: {
    profileDetails: {
      type: Object as PropType<TypeProfileDetails>,
    },
    publicChannels: {
      type: Array as PropType<
        CommunicationChannels_communicationChannels_CommunicationChannelResults_results[]
      >,
      required: true,
    },
    verifiedChannelList: {
      type: Array as PropType<
        CommunicationChannels_communicationChannels_CommunicationChannelResults_results[]
      >,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-edit", "add-contacts"],
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  components: {
    ProfileViewHeader,
    ProfileViewContactDetails,
    EmptyProfileViewContact,
  },
});
