
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { getSocialIcon } from "@/shared/utils/social";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import CustomListContacts from "@/shared/components/CustomList/CustomListContacts.vue";

export default defineComponent({
  props: {
    socials: {
      type: Object as PropType<CommunicationChannels_communicationChannels_CommunicationChannelResults_results>,
      required: true,
    },
  },
  emits: ["add-contacts"],
  setup() {
    const { t } = useI18n();

    return {
      t,
      getSocialIcon,
    };
  },
  components: { CommonCardLayout, CustomListContacts },
});
