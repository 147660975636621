
import { defineComponent, PropType, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { getSocialIcon } from "@/shared/utils/social";
import get from "lodash/get";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputSwitch from "@/shared/components/Forms/InputSwitch.vue";
import { message } from "ant-design-vue";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";

export default defineComponent({
  emits: ["on-edit"],
  props: {
    channel: {
      type: Object as PropType<CommunicationChannels_communicationChannels_CommunicationChannelResults_results>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const formState = reactive({
      isPublic: props.channel.isPublic,
      channelId: props.channel.id,
    });

    const { handleUpdateChannel, updateChannelLoading } =
      useCommunicationChannelList();

    const handleSwitchChange = async (isPublic) => {
      console.log("handleSwitchMainChannel:value", isPublic);
      try {
        await handleUpdateChannel({
          channelId: formState.channelId,
          isPublic: formState.isPublic,
        });

        if (isPublic) {
          //display the contact in profile
          message.info(
            t("Contact {value} will be shown on profile", {
              value: props.channel.value,
            })
          );
        } else {
          //Remove contact display in profile
          message.info(
            t("Contact {value} will be hidden on profile", {
              value: props.channel.value,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    return { t, formState, handleSwitchChange, updateChannelLoading };
  },
  methods: {
    getSocialIcon,
    get,
  },
  components: { FormGroup, InputSwitch, PlatformIcon },
});
