import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CustomListContacts = _resolveComponent("CustomListContacts")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, {
    class: "profile-view-contact-details",
    title: _ctx.t('Contacts')
  }, {
    "title-right": _withCtx(() => [
      _createVNode(_component_a_button, {
        class: "ml-auto",
        size: "small",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-contacts')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("+ Add Contacts")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_CustomListContacts, { channelList: _ctx.socials }, null, 8, ["channelList"])
    ]),
    _: 1
  }, 8, ["title"]))
}