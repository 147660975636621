import includes from "lodash/includes";
import DiscordIcon from "@/assets/social/discord.svg";
import InstagramIcon from "@/assets/social/instagram.svg";
import SlackIcon from "@/assets/social/slack.svg";
import TelegramIcon from "@/assets/social/telegram.svg";
import TwitterIcon from "@/assets/social/twitter.svg";
import EmailIcon from "@/assets/social/email.svg";
import MobileIcon from "@/assets/social/mobile.svg";
import DomainIcon from "@/assets/social/domain.svg";

export const getSocialIcon = (platform: string) => {
  return {
    discord: DiscordIcon,
    instagram: InstagramIcon,
    slack: SlackIcon,
    telegram: TelegramIcon,
    twitter: TwitterIcon,
    email: EmailIcon,
    mobile: MobileIcon,
    DomainChannel: DomainIcon,
    MobileChannel: MobileIcon,
    EmailChannel: EmailIcon,
  }[platform];
};

/**
 * CommunicationChannels_communicationChannels_CommunicationChannelResults_results
 * doesn't have enum for the typename, see file directly for possible __typename for checking
 */
export const isSocialPlatform = (
  CommunicationChannels_results__typename: string
) => {
  return includes(["SocialChannel"], CommunicationChannels_results__typename);
};
