
import { defineComponent } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  emits: ["update:value", "switch-change"],
  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const switchValue = useVModel(props, "value");

    const onSwitchChange = (checked: boolean, event: Event) => {
      emit("switch-change", checked, event);
    };

    return { switchValue, onSwitchChange };
  },
  components: { FormItem },
});
