import { computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { communicationChannelsGql } from "@/api/communicationChannels/communicationChannelList";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { CommunicationChannelFiltersInput } from "../../../../__generated__/globalTypes";
import isEmpty from "lodash/isEmpty";
import { updateChannelGql } from "@/api/communicationChannels/updateChannel";
import {
  UpdateChannel,
  UpdateChannelVariables,
  UpdateChannel_updateChannel_MobileChannel,
} from "@/api/communicationChannels/__generated__/UpdateChannel";
import { apiErrorCodes } from "@/shared/utils/constants";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";

export const useCommunicationChannelList = (
  filterList?: CommunicationChannelFiltersInput
) => {
  /**
   * GET COMMUNICATION CHANNELS
   */
  const communicationChannelsQuery = useQuery(
    communicationChannelsGql,
    { input: filterList },
    { fetchPolicy: "network-only" }
  );
  const channelList = computed(() => {
    const parsedResponse =
      parseGqlResponse<CommunicationChannels_communicationChannels_CommunicationChannelResults>(
        "CommunicationChannelResults",
        communicationChannelsQuery.result.value
      );

    return parsedResponse.data?.results || [];
  });
  /**
   * END GET COMMUNICATION CHANNELS
   */
  /**
   * UPDATE CHANNELS
   */
  const updateChannelMutation = useCustomMutation<
    UpdateChannel,
    UpdateChannelVariables
  >(updateChannelGql);
  const handleUpdateChannel = async ({ channelId, isPublic }) => {
    const updateChannelResponse = await updateChannelMutation.mutate({
      input: {
        channelId,
        isPublic,
      },
    });
    const parsedResponse =
      parseGqlResponse<UpdateChannel_updateChannel_MobileChannel>(
        "MobileChannel",
        updateChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleUpdateChannel:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !updateChannelResponse) {
      throw new Error("Failed to update mobile channel");
    }

    return parsedResponse.data;
  };
  /**
   * END UPDATE CHANNELS
   */

  return {
    loading: computed(() => communicationChannelsQuery.loading.value),
    channelList,
    handleRefetchCommunicationChannel: communicationChannelsQuery.refetch,
    handleUpdateChannel,
    updateChannelLoading: computed(() => updateChannelMutation.loading.value),
  };
};
