import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileUpdate = _resolveComponent("ProfileUpdate")!
  const _component_ProfileViewSelectContacts = _resolveComponent("ProfileViewSelectContacts")!
  const _component_ProfileView = _resolveComponent("ProfileView")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('Profile')
  }, {
    default: _withCtx(() => [
      (_ctx.mode === _ctx.ProfileMode.UPDATE_PROFILE)
        ? (_openBlock(), _createBlock(_component_ProfileUpdate, {
            key: 0,
            onOnView: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mode = _ctx.ProfileMode.VIEW_PROFILE))
          }))
        : (_ctx.mode === _ctx.ProfileMode.ADD_CONTACTS)
          ? (_openBlock(), _createBlock(_component_ProfileViewSelectContacts, {
              key: 1,
              verifiedChannelList: _ctx.verifiedChannelList,
              onOnView: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mode = _ctx.ProfileMode.VIEW_PROFILE))
            }, null, 8, ["verifiedChannelList"]))
          : (_openBlock(), _createBlock(_component_ProfileView, {
              key: 2,
              publicChannels: _ctx.publicChannels,
              verifiedChannelList: _ctx.verifiedChannelList,
              onOnEdit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.mode = _ctx.ProfileMode.UPDATE_PROFILE)),
              onAddContacts: _cache[3] || (_cache[3] = ($event: any) => (_ctx.mode = _ctx.ProfileMode.ADD_CONTACTS)),
              loading: _ctx.loading
            }, null, 8, ["publicChannels", "verifiedChannelList", "loading"]))
    ]),
    _: 1
  }, 8, ["title"]))
}