import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-contact-item flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_space, {
      size: 8,
      class: "flex-1"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PlatformIcon, {
          class: "channel-item__icon",
          name: _ctx.get(_ctx.channel, 'provider', _ctx.channel.__typename)
        }, null, 8, ["name"]),
        _createVNode(_component_a_row, {
          gutter: 8,
          class: "profile-contact-item__detail flex items-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { class: "profile-contact-item__detail-col" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_typography_paragraph, { class: "m-0" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.channel.value), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                (_ctx.channel.isPrimary)
                  ? (_openBlock(), _createBlock(_component_a_alert, {
                      key: 0,
                      message: _ctx.t('Main'),
                      type: "success",
                      banner: "",
                      "show-icon": false
                    }, null, 8, ["message"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_FormGroup, {
      "wrapper-col": { span: 24 },
      model: _ctx.formState,
      layout: "vertical",
      loading: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputSwitch, {
          class: "mb-0",
          value: _ctx.formState.isPublic,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.isPublic) = $event)),
          disabled: _ctx.updateChannelLoading,
          onSwitchChange: _ctx.handleSwitchChange
        }, null, 8, ["value", "disabled", "onSwitchChange"])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}