import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    class: "input-switch"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_switch, {
        checked: _ctx.switchValue,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.switchValue) = $event)),
        class: "ml-2",
        onChange: _ctx.onSwitchChange,
        label: _ctx.label,
        disabled: _ctx.$attrs.disabled
      }, null, 8, ["checked", "onChange", "label", "disabled"]),
      _createElementVNode("label", null, _toDisplayString(_ctx.label), 1)
    ]),
    _: 1
  }, 8, ["name", "value", "rules", "extra", "help"]))
}