import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileViewHeader = _resolveComponent("ProfileViewHeader")!
  const _component_ProfileViewContactDetails = _resolveComponent("ProfileViewContactDetails")!
  const _component_EmptyProfileViewContact = _resolveComponent("EmptyProfileViewContact")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProfileViewHeader, {
      onOnEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-edit')))
    }),
    (_ctx.publicChannels.length > 0)
      ? (_openBlock(), _createBlock(_component_ProfileViewContactDetails, {
          key: 0,
          profileDetails: _ctx.profileDetails,
          socials: _ctx.publicChannels,
          onAddContacts: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('add-contacts')))
        }, null, 8, ["profileDetails", "socials"]))
      : (_openBlock(), _createBlock(_component_EmptyProfileViewContact, {
          key: 1,
          onAddContacts: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('add-contacts'))),
          verifiedChannelList: _ctx.verifiedChannelList,
          loading: _ctx.loading
        }, null, 8, ["verifiedChannelList", "loading"]))
  ]))
}