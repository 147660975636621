import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile-view-header__name" }
const _hoisted_2 = { class: "profile-view-header__actions ml-auto" }
const _hoisted_3 = { class: "profile-view-header__details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, { class: "profile-view-header mb-8" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, { type: "flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_avatar, {
                class: "mr-6",
                size: "large",
                src: _ctx.avatarImage
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { class: "flex-1" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_a_typography_title, {
                  level: 4,
                  class: _normalizeClass(_ctx.isProfileAnonymous && 'lighter')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isProfileAnonymous ? _ctx.t("You are anonymous") : _ctx.selectedUser.name), 1)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_button, {
                    size: "small",
                    onClick: _ctx.handleShareProfileClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Share")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    size: "small",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-edit')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Edit Profile")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_typography_paragraph, { class: "small lighter" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isProfileAnonymous
                ? _ctx.t("Your profile is visible to anyone in the internet")
                : _ctx.t(
                    "Your profile is visible to any service where you will login with {merchantBrand}",
                    {
                      merchantBrand: _ctx.config.merchantBrand,
                    }
                  )), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}