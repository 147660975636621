
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import ProfileUpdate from "@/web/views/Profile/ProfileUpdate/ProfileUpdate.vue";
import ProfileView from "@/web/views/Profile/ProfileView/ProfileView.vue";
import ProfileViewSelectContacts from "@/web/views/Profile/ProfileSelectContacts/ProfileViewSelectContacts.vue";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";
import filter from "lodash/filter";

export enum ProfileMode {
  VIEW_PROFILE = "VIEW PROFILE",
  UPDATE_PROFILE = "UPDATE",
  ADD_CONTACTS = "ADD CONTACTS",
}

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const mode = ref(ProfileMode.VIEW_PROFILE);

    const {
      channelList: verifiedChannelList,
      loading,
      handleRefetchCommunicationChannel,
    } = useCommunicationChannelList({
      isVerified: true,
    });

    /**
     * publicChannels will be used to display in the profile contact list
     */
    const publicChannels = computed(() =>
      filter(verifiedChannelList.value, (channel) => channel?.isPublic)
    );

    return {
      t,
      mode,
      ProfileMode,
      verifiedChannelList,
      publicChannels,
      loading,
      handleRefetchCommunicationChannel,
    };
  },
  components: {
    PageLayout,
    ProfileView,
    ProfileUpdate,
    ProfileViewSelectContacts,
  },
});
