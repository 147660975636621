import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "profile-update__from-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_AvatarUploader = _resolveComponent("AvatarUploader")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, { class: "profile-update" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_button, {
          type: "text",
          class: "p-0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-view'))),
          size: "small"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.BackIcon,
              alt: "back",
              class: "mr-3",
              height: "24"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_title, {
          level: 3,
          class: "m-0"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Edit Profile")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_row, { class: "profile-update-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { class: "profile-update-content__avatar" }, {
            default: _withCtx(() => [
              _createVNode(_component_AvatarUploader, {
                textInstruction: _ctx.t('Drop or browse'),
                onImageChange: _ctx.handleAvatarChange,
                avatar: _ctx.avatar
              }, null, 8, ["textInstruction", "onImageChange", "avatar"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { class: "profile-update-content__details flex-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_FormGroup, {
                class: "profile-form",
                "wrapper-col": { span: 24 },
                model: _ctx.formProfileState,
                layout: "vertical",
                loading: _ctx.updateProfileLoading,
                onFinish: _ctx.confirmed
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    label: "Name ",
                    name: "fullName",
                    placeholder: "John Smeeth",
                    value: _ctx.formProfileState.fullName,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formProfileState.fullName) = $event)),
                    rules: [_ctx.makeRequiredRule(_ctx.t('Name is required'))]
                  }, null, 8, ["value", "rules"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_a_button, {
                        class: "profile-update__button",
                        loading: _ctx.updateProfileLoading,
                        type: "primary",
                        "html-type": "submit"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Save")), 1)
                        ]),
                        _: 1
                      }, 8, ["loading"]),
                      _createVNode(_component_a_button, {
                        type: "text",
                        class: "profile-update__button",
                        disabled: _ctx.updateProfileLoading,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-view')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Cancel")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    (!_ctx.isProfileAnonymous || !!_ctx.avatar)
                      ? (_openBlock(), _createBlock(_component_a_button, {
                          key: 0,
                          onClick: _ctx.handleStayAnonymous,
                          type: "text",
                          class: "profile-update__button profile-update__button-anonymous",
                          disabled: _ctx.updateProfileLoading
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Stay Anonymous")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["model", "loading", "onFinish"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}