import gql from "graphql-tag";

export const communicationChannelsGql = gql`
  query CommunicationChannels($input: CommunicationChannelFiltersInput) {
    communicationChannels(input: $input) {
      ... on CommunicationChannelResults {
        results {
          id
          value
          isVerified
          isPrimary
          isPublic
          verified
          created
          updated
          ... on SocialChannel {
            provider
          }
          ... on DomainChannel {
            verificationCode
            verificationFile
            domainVerificationFileUrl
            verificationMethod
            key
            callbackUrl
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
