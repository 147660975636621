
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import DefaultAvatarIcon from "@/assets/profile/default-avatar.svg";
import isEmpty from "lodash/isEmpty";
import { copyText } from "vue3-clipboard";
import { config } from "@/shared/utils/config";
import { useAccount } from "@/shared/composables/Profile/useAccount";
import { generatePublicUrl } from "@/shared/utils/avatarada";
import { message } from "ant-design-vue";

export default defineComponent({
  emits: ["on-edit"],
  setup() {
    const { t } = useI18n();
    const { selectedUser } = useAccount();

    const handleShareProfileClick = () => {
      const userPublicUrl = generatePublicUrl(
        selectedUser?.value?.wallet.stakingKeyHash
      );

      console.log("handleShareProfileClick", userPublicUrl);
      copyText(userPublicUrl, undefined, (error) => {
        if (!error) {
          message.info(
            t("Your Public Profile URL has been copied to clipboard")
          );
        }
      });
    };

    return {
      t,
      config,
      selectedUser,
      isProfileAnonymous: computed(() => isEmpty(selectedUser?.value?.name)),
      avatarImage: computed(
        () => selectedUser.value.avatar80 || DefaultAvatarIcon
      ),
      handleShareProfileClick,
    };
  },
  components: { CommonCardLayout },
});
