
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BackIcon from "@/assets/icons/back.svg";
import ProfileContactItem from "@/web/views/Profile/ProfileSelectContacts/ProfileContactItem.vue";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results } from "@/api/communicationChannels/__generated__/CommunicationChannels";

export default defineComponent({
  emits: ["on-add-channel", "on-edit-channel", "on-delete-channel", "on-view"],
  props: {
    verifiedChannelList: {
      type: Array as PropType<
        CommunicationChannels_communicationChannels_CommunicationChannelResults_results[]
      >,
      required: true,
    },
  },
  setup(_props, { emit }) {
    const { t } = useI18n();

    return {
      t,
      BackIcon,
      backToProfile: () => {
        emit("on-view");
      },
    };
  },
  components: {
    CommonCardLayout,
    ProfileContactItem,
  },
});
