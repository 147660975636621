
import { computed, defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import AvatarUploader from "@/shared/components/AvatarUploader.vue";
import {
  makeEmailRule,
  makeRequiredRule,
  makeRequiredCheckedRule,
} from "@/shared/utils/validators/commonValidators";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import BackIcon from "@/assets/icons/back.svg";
import { useProfile } from "@/shared/composables/Profile/useProfile";
import { useStore } from "vuex";
import { useAccount } from "@/shared/composables/Profile/useAccount";

export default defineComponent({
  emits: ["on-view"],
  setup(_props, { emit }) {
    const { t } = useI18n();
    const { selectedUser } = useAccount();
    const store = useStore();
    const avatarFile = ref();

    const formProfileState = reactive({
      fullName: store.state?.userInfo?.name || "",
    });

    const handleAvatarChange = async (blob) => {
      avatarFile.value = blob;
    };

    const { updateProfileLoading, handleUpdateProfile } = useProfile();

    const confirmed = async () => {
      try {
        await handleUpdateProfile({
          name: formProfileState.fullName,
          avatar: avatarFile?.value,
        });

        // emit on success
        emit("on-view");
      } catch (error) {
        console.error("handleUpdateProfile:error", error);
      }
    };

    const handleStayAnonymous = async () => {
      try {
        await handleUpdateProfile({
          name: "",
          avatar: null,
          deleteAvatar: true,
        });

        // emit on success
        emit("on-view");
      } catch (error) {
        console.error("handleStayAnonymous:error", error);
      }
    };

    return {
      t,
      formProfileState,
      BackIcon,
      updateProfileLoading,
      confirmed,
      handleAvatarChange,
      handleStayAnonymous,
      avatar: computed(() => store.state?.userInfo?.avatar400),
      isProfileAnonymous: computed(() => !selectedUser?.value?.name),
    };
  },
  methods: {
    makeRequiredRule,
    makeRequiredCheckedRule,
    makeEmailRule,
  },
  components: {
    CommonCardLayout,
    AvatarUploader,
    FormGroup,
    InputText,
  },
});
