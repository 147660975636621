import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EmptyList = _resolveComponent("EmptyList")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: "",
        paragraph: { rows: 4 }
      }))
    : (_openBlock(), _createBlock(_component_EmptyList, {
        key: 1,
        title: _ctx.t('Contacts are empty'),
        body: _ctx.body
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            size: "small",
            onClick: _ctx.handleContacts
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.noVerifiedChannel
            ? _ctx.t("To Communication Channels >")
            : _ctx.t("+ Add Contacts")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["title", "body"]))
}