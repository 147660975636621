
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import EmptyList from "@/shared/components/Layouts/EmptyList.vue";
import routeNames from "@/web/router/routeNames";
import { useRouter } from "vue-router";
import { TypeProfileDetailsSocial } from "@/web/types/Profile";
import { isEmpty } from "lodash";

export default defineComponent({
  props: {
    verifiedChannelList: {
      type: Array as PropType<TypeProfileDetailsSocial[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["add-contacts"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();

    const noVerifiedChannel = computed(() =>
      isEmpty(props.verifiedChannelList)
    );

    /**
     * If there's no verified channel redirect to communication channel
     */
    const handleContacts = () => {
      // Add contacts, show Select Contact component
      if (!noVerifiedChannel.value) {
        emit("add-contacts");
        return;
      }

      //  To Communication Channels
      router.push({ name: routeNames.communicationChannels });
    };

    return {
      t,
      handleContacts,
      noVerifiedChannel,
      body: computed(() =>
        noVerifiedChannel.value
          ? t(
              "Contacts will be visible in your public profile as well.\n To add contacts visit communication channels."
            )
          : t("Contacts will be visible in your public profile as well.")
      ),
    };
  },
  components: { EmptyList },
});
